import { Button, Text } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';
import classes from '@components/HamburgerMenu/HamburgerMenu.module.scss';
import HamburgerMenuBarsIcon from '@components/HamburgerMenu/HamburgerMenuBarsIcon';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import UserSvg from 'public/icons/user.svg';

interface Props extends React.ComponentPropsWithoutRef<'button'> {
  onClick?: () => void;
  isMenuOpen?: boolean;
}

const NavbarAbTestAtm4635HamburgerMenuToggle = ({ onClick, isMenuOpen, ...buttonProps }: Props) => {
  const { isDesktop } = useActiveBreakpoint();
  const { t } = useTranslation(['messages']);

  return isDesktop ? (
    <Button onClick={onClick} color={'tertiary'} {...buttonProps}>
      <UserSvg />
      <Text elementType={'span'} UNSAFE_className={'ml-400'}>
        {t('YOUR_ACCOUNT')}
      </Text>
    </Button>
  ) : (
    <button
      className={classNames(classes.HamburgerMenu__hamburger, isMenuOpen && classes.HamburgerMenu__hamburgerOpen)}
      onClick={onClick}
      {...buttonProps}
    >
      <HamburgerMenuBarsIcon />
    </button>
  );
};

export default NavbarAbTestAtm4635HamburgerMenuToggle;
