import { Dropdown, DropdownPopover, DropdownTrigger } from '@lmc-eu/spirit-web-react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';
import { Fragment, useState } from 'react';
import { MenuItemLinkType, MenuItemTypes, MenuItemUnionType } from '@components/HamburgerMenu/HamburgerMenu';
import Counter from '@components/utilities/Counter/Counter';
import DropdownItem from '@components/utilities/DropdownItem/DropdownItem';
import ChevronDownSVG from '@icons/chevron-down.svg';
import { useGenerateRoute, useGetLocalizedRoute } from '@libs/localizedRoute';
import { Route } from '@libs/routes';
import { getUrlWithParameters } from '@libs/urlUtils';
import classes from './DiscoverCompaniesDropdown.module.scss';
import { InterestingProfilesGalleryController } from '../../../generated/phpConstants';

const DiscoverCompaniesDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['messages']);
  const getLocalizedRoute = useGetLocalizedRoute();
  const generateRoute = useGenerateRoute();

  const handleDropdownItemClick = (linkItem: MenuItemLinkType) => {
    if (linkItem.onClick) {
      linkItem.onClick();
    }
  };

  const renderMenuItem = (item: MenuItemUnionType) => {
    switch (item.type) {
      case MenuItemTypes.Line:
        return <hr />;
      case MenuItemTypes.Link:
        return (
          <NextLink
            href={item.url}
            rel={item.rel}
            className={classes.DiscoverCompaniesDropdown__link}
            target={item.target}
          >
            <DropdownItem
              onClick={() => handleDropdownItemClick(item)}
              disabled={item.disabled}
              className={classes.DiscoverCompaniesDropdown__item}
              dataCyItem={item.dataCyItem}
              isInGroup={item.isInGroup}
              isSelected={item.isSelected}
              data-icom={item.dataIcom}
            >
              {item.title}
            </DropdownItem>
          </NextLink>
        );
      default:
        return null;
    }
  };

  const menuItems: MenuItemUnionType[] = [];

  menuItems.push({
    type: MenuItemTypes.Link,
    title: (
      <>
        <div>
          {t('CHECK_OUT_THESE_COMPANIES')}
          <Counter>{InterestingProfilesGalleryController.FULL_GALLERY_PROFILES_COUNT}</Counter>
        </div>
        <div className="text-secondary">{t('PROFILE_GALLERY_PAGE_TITLE')}</div>
      </>
    ),
    url: getUrlWithParameters(getLocalizedRoute(Route.interestingProfileGallery), {
      zdroj: 'menu',
      premium: InterestingProfilesGalleryController.COUNT_OF_PREMIUM_COMPANIES_TO_SHOW,
    }),
  });

  menuItems.push({
    type: MenuItemTypes.Line,
  });

  menuItems.push({
    type: MenuItemTypes.Link,
    title: (
      <>
        <div>{t('COMPANY_LIST_MENU_LINK_TITLE')}</div>
        <div className="text-secondary">{t('COMPANY_LIST_MENU_LINK_SUBTITLE')}</div>
      </>
    ),
    url: getLocalizedRoute(Route.companyList),
  });

  menuItems.push({
    type: MenuItemTypes.Line,
  });

  menuItems.push({
    type: MenuItemTypes.Link,
    title: (
      <>
        <div>{t('PROFILE_MATCHING_MENU_LINK_TITLE')}</div>
        <div className="text-secondary">{t('PROFILE_MATCHING_MENU_LINK_SUBTITLE')}</div>
      </>
    ),
    url: getUrlWithParameters(getLocalizedRoute(Route.profileMatchingResult), {
      zdroj: 'menu',
    }),
  });

  menuItems.push({
    type: MenuItemTypes.Line,
  });

  menuItems.push({
    type: MenuItemTypes.Link,
    title: t('ADD_REVIEW', { ns: 'profile' }),
    url: generateRoute('front.questionnaire.basic', { zdroj: 'atmoskop-web', typ: 'atmoskop-web' }),
    rel: 'nofollow',
  });

  return (
    <Dropdown
      id="discover-companies-dropdown"
      isOpen={isOpen}
      onToggle={() => setIsOpen((prevState) => !prevState)}
      placement="bottom-start"
      UNSAFE_className={classes.DiscoverCompaniesDropdown}
    >
      <DropdownTrigger elementType="div">
        <div
          className={classNames(
            'link-secondary',
            classes.DiscoverCompaniesDropdown__trigger,
            isOpen && classes.DiscoverCompaniesDropdown__triggerOpen,
          )}
        >
          {t('DISCOVER_COMPANIES_MENU_TITLE')} <ChevronDownSVG />
        </div>
      </DropdownTrigger>
      <DropdownPopover UNSAFE_className={classes.DiscoverCompaniesDropdown__popover}>
        {menuItems.map((item, index) => (
          <Fragment key={index}>{renderMenuItem(item)}</Fragment>
        ))}
      </DropdownPopover>
    </Dropdown>
  );
};

export default DiscoverCompaniesDropdown;
