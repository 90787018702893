import { useQuery } from '@apollo/client';
import Head from 'next/head';
import React from 'react';
import FeatureToggle from '@components/utilities/FeatureToggle/FeatureToggle';
import {
  FederatedLoginPublicCredentialsQuery,
  FederatedLoginPublicCredentialsQueryVariables,
} from '@graphql/generated/types/graphqlTypes';
import { FEDERATED_LOGIN_PUBLIC_CREDENTIALS } from '@graphql/queries/federatedLogin';
import { useGenerateRoute } from '@libs/localizedRoute';

const GoogleOneTapLogin = () => {
  const generateRoute = useGenerateRoute();

  const { data } = useQuery<FederatedLoginPublicCredentialsQuery, FederatedLoginPublicCredentialsQueryVariables>(
    FEDERATED_LOGIN_PUBLIC_CREDENTIALS,
  );

  if (!data?.federatedLoginPublicCredentials.googleOauthClientId) {
    return null;
  }

  return (
    <FeatureToggle identifier="google_login">
      <Head>
        <script src="https://accounts.google.com/gsi/client" async defer></script>
      </Head>
      <div
        id="g_id_onload"
        data-client_id={data?.federatedLoginPublicCredentials.googleOauthClientId}
        data-context="signin"
        data-ux_mode="redirect"
        data-login_uri={generateRoute('front.googleLogin.oneTapCheck')}
        data-itp_support="true"
        data-cancel_on_tap_outside="false"
      ></div>
    </FeatureToggle>
  );
};

export default GoogleOneTapLogin;
